import React, { useEffect, useRef } from "react";
// import serviceBlockImg from "../assets/images/feature.webp";
import serviceBlockImg from "../assets/images/ggp/kid1.png";

import Title from "./Title/Title";
import Para from "./Title/Para";
import { AiFillCar } from "react-icons/ai";
import { GiWorld } from "react-icons/gi";
import { FaTruckMoving } from "react-icons/fa";
import { RiCustomerService2Fill, RiShip2Fill } from "react-icons/ri";
import item1 from '../assets/images/ggp/features/tab_item1.png'
import item2 from '../assets/images/ggp/features/tab_item2.png'
import item3 from '../assets/images/ggp/features/tab_item3.png'
import item4 from '../assets/images/ggp/features/tab_item4.png'
import item5 from '../assets/images/ggp/features/tab_item5.png'
import item6 from '../assets/images/ggp/features/tab_item6.png'

import AOS from "aos";

const Features = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  return (
    <>
      <section className="features_wrapper py-5">
        <Title title="What's New in Good Good Piggy?" />

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-transparent border-0">
                <div className="card-body text-start">
                  <div
                    className="features"
                    data-aos="fade-down"
                    data-aos-duration="500"
                  >
                    <div className="feature-icon">
                      <span>
                        <img src={item1} className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good Good Missions</h3>
                      <p>Helps kids learn and earn their pocket money</p>
                    </div>
                  </div>

                  <div
                    className="features"
                    data-aos="fade-down"
                    data-aos-duration="700"
                  >
                    <div className="feature-icon">
                      <span>
                        <img src={item2} className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good Good Shop</h3>
                      <p>
                        Helps kids learn concepts of spending, saving and
                        budgeting
                      </p>
                    </div>
                  </div>
                  <div
                    className="features"
                    data-aos="fade-down"
                    data-aos-duration="900"
                  >
                    <div className="feature-icon">
                      <span>
                        <img src={item3} className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good Good Tracker</h3>
                      <p>Helps kids learn and earn their pocket money</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4 feature-img">
              <div className="card bg-transparent border-0">
                <div className="card-body text-center">
                  <img
                    src={serviceBlockImg}
                    alt=""
                    className="img-fluid mb-4 service_phone"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-transparent border-0">
                <div className="card-body text-start">
                  <div
                    className="features"
                    data-aos="fade-down"
                    data-aos-duration="500"
                  >
                    <div className="feature-icon">
                      <span>
                        <img src={item4} className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good Good Concierge</h3>
                      <p>Offers ease and convenience for on-the-go parenting</p>
                    </div>
                  </div>
                  <div
                    className="features"
                    data-aos="fade-down"
                    data-aos-duration="700"
                  >
                    <div className="feature-icon">
                      <span>
                        <img src={item5} className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good Good Community</h3>
                      <p>Helps to network, learn and grow Kid's and Parent's</p>
                    </div>
                  </div>
                  <div
                    className="features"
                    data-aos="fade-down"
                    data-aos-duration="900"
                  >
                    <div className="feature-icon">
                      <span>
                        <img src={item6} className="icons" />
                      </span>
                    </div>
                    <div className="feature-text">
                      <h3>Good Good Dashboard</h3>
                      <p>
                        Helps to control and monitor kids growth in real time
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
