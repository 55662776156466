import React, { useState } from "react";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import BrandPartners from "../components/BrandPartners";
import Title from "../components/Title/Title";
import Para from "../components/Title/Para";
import {
  trasnactionTracker,
  orderOverview,
  parentData,
  kidData,
  kidcontrolpanel,
  connectHub,
  autoApprove,
  goaltracker,
  dataPrivacy
} from "../assets/data/faq";
import Accordion from "react-bootstrap/Accordion";

import Phones from "../assets/images/ggp/banner-app.png";
import apple from "../assets/images/ggp/apple.png";
import google from "../assets/images/ggp/google.png";
import YoutubeReview from "../components/YoutubeReview";
import Testimonial from "../components/Testimonial";
import Institutional from "../components/Instiutional/Institutional";

import books from "../assets/images/books.png";
import quiz from "../assets/images/brain.png";
import podcast from "../assets/images/podcast.png";

const AppPage = () => {
  const [data1, setData1] = useState(trasnactionTracker);
  const [show, setShow] = useState(false);
  const [activeId, setActiveId] = useState("");

  const handleShow = (id) => {
    setActiveId(id);
    setShow(true);
  };

  return (
    <>
      <section className="banner about-app">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 banner-left">
              <h1 className="banner-heading">
                <span>Good Good Piggy</span> <br />
                Where learning and money management unite
              </h1>
              <div className="download-btn-group">
                <a
                  href="https://play.google.com/store/apps/details?id=com.goodgoodpiggy"
                  target="_blank"
                  className="banner-btn"
                >
                  <div className="market-img-div">
                    <img src={google} className="img-fluid play-store" />
                  </div>
                </a>
                <a
                  href="https://apps.apple.com/app/good-good-piggy/id1663820984"
                  target="_blank"
                  className="banner-btn"
                >
                  <div className="market-img-div">
                    <img src={apple} className="img-fluid app-store" />
                  </div>
                </a>
              </div>

              <div className="lang-para">
                <p className="para-desc">Now available in Hindi & English</p>
              </div>
            </div>
            <div className="col-lg-6 col-12 banner-right">
              <img
                src={Phones}
                className="banner-img-fluid"
                width={80}
                height={80}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="dashboard-section py-5 mt-5 mb-5">
        <div className="container">
          <Title title="The Good Good Piggy Edge" />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 col-md-4">
              {/* <h2 className="main-heading">The Good Good Piggy Edge</h2> */}
              <div className="dashboard-wrapper">
                <Tab.Container defaultActiveKey="parent">
                  <Nav variant="tabs" className="dashboard-topbar">
                    <Nav.Item>
                      <Nav.Link eventKey="parent">Parents</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="kid">Kids</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="dashboard-content">
                    <Tab.Pane eventKey="parent">
                      <div className="row">
                        <div className="col-lg-12 col-12 faq-flex">
                          {parentData?.map((faq, id) => {
                            return (
                              <div className="parent-item">
                                <h4
                                  style={{ width: "100%" }}
                                  className="accordion-caption"
                                >
                                  {faq.ques}
                                </h4>
                                <p>{faq.ans}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content className="dashboard-content">
                    <Tab.Pane eventKey="kid">
                      <div className="row">
                        <div className="col-lg-12 col-12 faq-flex">
                          {kidData?.map((faq, id) => {
                            return (
                              <div className="parent-item">
                                <h4
                                  style={{ width: "90%" }}
                                  className="accordion-caption"
                                >
                                  {faq.ques}
                                </h4>{" "}
                                <p>{faq.ans}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 d-flex align-items-center">
              <iframe
                style={{ borderRadius: "18px" }}
                width="100%"
                height="620"
                src="https://www.youtube.com/embed/lkp5WNQm5nY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <BrandPartners />

      <section className="gold-app-features">
        <div className="container ">
          <Title title="Gold Premium Features" />
          <div className="section-para text-center pb-3 wid-50 mx-auto">
            <p className="feature-desc-head">
              Offering an array of premium educational features designed to
              empower kids with essential money management skills. With Good
              Good Piggy Gold, learning about finance becomes an engaging
              adventure, guiding children towards financial literacy in a fun
              and interactive way.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card feature-card text-center">
                <div>
                  <img
                    src={books}
                    alt=""
                    className="img-fluid app-feature-image"
                  />
                </div>
                <div className="card-body p-3">
                  <h2 className="feature-name">Digital Storybooks</h2>
                  <p className="text-center feature-desc">
                    Immerse yourself in a world of storytelling that combines
                    entertainment with financial education. Explore diverse
                    narratives that teach important concepts like earning,
                    spending, and making wise financial choices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card feature-card text-center">
                <div className="service-image-div">
                  <img
                    src={quiz}
                    alt=""
                    className="img-fluid app-feature-image"
                  />
                </div>
                <div className="card-body p-3">
                  <h2 className="feature-name">Money Maths Quiz</h2>
                  <p className="text-center feature-desc">
                    Challenge yourself with fun and interactive quizzes that
                    sharpen your math skills while teaching you about money.
                    From basic arithmetic to advanced concepts, our quizzes make
                    learning finance exciting!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="card feature-card text-center">
                <div className="service-image-div">
                  <img
                    src={podcast}
                    alt=""
                    className="img-fluid app-feature-image"
                  />
                </div>
                <div className="card-body p-3">
                  <h2 className="feature-name">
                    Podcast for Kids on Money Management
                  </h2>
                  <p className="text-center feature-desc">
                    Listen to captivating stories while learning valuable
                    lessons about money. Our bite sized podcast series is
                    designed to entertain and educate young listeners on topics
                    like saving, budgeting, and smart spending.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 

      <Testimonial />

      <Institutional />

      <YoutubeReview />

      <section className="faqs-section my-5 faqs">
        <div className="container">
          <Title title="Our FAQ's" />
          <div className="faqs-wrapper">
            <Tab.Container defaultActiveKey="transactionTracker">
              <Nav variant="tabs" className="faqs-topbar">
                <Nav.Item>
                  <Nav.Link eventKey="transactionTracker">
                    Transaction Tracker
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="order_overview">Order Overview</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="kid_control_panel">
                    Kid Control Panel
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="goal_tracker">Goal Tracker</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="data_privacy-and-security">
                    Data Privacy & security
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="auto_approve">Auto Approve</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="connect_hub">Connect Hub</Nav.Link>
                </Nav.Item>

                {/*    <Nav.Item>
                  <Nav.Link eventKey="secret_passcodes">
                    Secret passcodes
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="blessings">Blessings</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="thinking_assistant">Thinking Assistant</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="funtastic_shop_zone">Fun-tastic Shop Zone</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="challenge_creator">Challenge Creator</Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="pocket_money_planner">Pocket Money Planner</Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content className="faqs-content">
                <Tab.Pane eventKey="transactionTracker">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {trasnactionTracker?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="order_overview">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {orderOverview?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="kid_control_panel">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {kidcontrolpanel?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="goal_tracker">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {goaltracker?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="data_privacy-and-security">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {dataPrivacy?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="auto_approve">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {autoApprove?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="connect_hub">
                  <div className="row">
                    <div className="col-lg-12 col-12 faq-flex">
                      {connectHub?.map((faq, id) => {
                        return (
                          <div className="accordion-item">
                            <button
                              className="faq-ques accordion-btn"
                              onClick={() => handleShow(faq.id)}
                            >
                              <h4
                                style={{ width: "90%" }}
                                className="accordion-caption"
                              >
                                {faq.ques}
                              </h4>{" "}
                              <span className="accordion-icon">+</span>
                            </button>

                            {show && activeId === faq.id && (
                              <div
                                align="justify"
                                style={{ width: "90%" }}
                                className="accordion-content"
                              >
                                <p>{faq.ans}</p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppPage;
